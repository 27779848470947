import clsx from 'clsx';
import Link, { LinkProps } from 'next/link';

import { AdjustStyles, ParagraphStyleType } from '../paragraph/paragraph';
import paragraphStyles from '../paragraph/paragraph.module.scss';

import styles from './link.module.scss';

interface SharedLinkProps extends LinkProps {
  children?: React.ReactNode;
  className?: string;
  styleType?: ParagraphStyleType;
  adjustStyles?: AdjustStyles;
  isWrapper?: boolean;
}

export const SharedLink: React.FC<SharedLinkProps> = ({
  href,
  children,
  className,
  adjustStyles,
  styleType = 'p3-paragraph',
  isWrapper,
}) => {
  if (!href) return null;

  const adjustedStyles = {
    ['--font-family' as string]: adjustStyles?.fontFamily,
    ['--font-size' as string]: adjustStyles?.fontSize,
    ['--font-weight' as string]: adjustStyles?.fontWeight,
    ['--letter-spacing' as string]: adjustStyles?.letterSpacing,
    ['--line-height' as string]: adjustStyles?.lineHeight,
    ['--text-align' as string]: adjustStyles?.textAlign,
    ['--text-color' as string]: adjustStyles?.color,
    ['--margin-block' as string]: adjustStyles?.marginBlock,
  };

  const extraProps =
    typeof href === 'string' &&
    (href.startsWith('http://') || href.startsWith('https://'))
      ? { rel: 'noreferrer noopener', target: '_blank' }
      : {};

  return (
    <Link
      href={href}
      className={clsx(
        className,
        styles['link'],
        styles[`link__${styleType}`],
        paragraphStyles[`paragraph`],
        paragraphStyles[`paragraph__${styleType}`],
        isWrapper && styles['link__wrapper'],
      )}
      style={adjustedStyles}
      {...extraProps}
    >
      {children}
    </Link>
  );
};
