import React from 'react';

import clsx from 'clsx';
import Link from 'next/link';

import styles from './button.module.scss';

export interface SharedButtonProps {
  label?: string | null;
  to?: string | null;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  loadingLabel?: string | null;
  className?: string;
  textColor?: string;
  rounded?: boolean;
  uppercase?: boolean;
  center?: boolean;
}

export const Button: React.FC<SharedButtonProps> = ({
  label,
  to: url,
  onClick,
  loading,
  loadingLabel,
  className,
  textColor = '#000',
  rounded,
  uppercase,
  center,
}) => {
  if (url && onClick) {
    console.error("Can't have both onClick and url prop in Button component");
  }

  const hoverTextColor = {
    '--button-text-color-hover': textColor,
  } as React.CSSProperties;

  const extraProps =
    typeof url === 'string' &&
    (url.startsWith('http://') || url.startsWith('https://'))
      ? { rel: 'noreferrer noopener', target: '_blank' }
      : {};

  return url ? (
    <Link
      href={url}
      className={clsx(
        className,
        styles['button'],
        rounded && styles['button--rounded'],
        uppercase && styles['button--uppercase'],
        center && styles['button--center'],
      )}
      style={hoverTextColor}
      {...extraProps}
    >
      {label || ''}
    </Link>
  ) : (
    <button
      onClick={onClick}
      disabled={loading}
      className={clsx(
        className,
        styles['button'],
        rounded && styles['button--rounded'],
        uppercase && styles['button--uppercase'],
        center && styles['button--center'],
      )}
      style={hoverTextColor}
    >
      {loading ? loadingLabel : label}
    </button>
  );
};
