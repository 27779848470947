import React from 'react';

import clsx from 'clsx';

import styles from './paragraph.module.scss';

export type ParagraphType = 'p' | 'span';

export type ParagraphStyleType =
  | 'p1-preamble'
  | 'p2-paragraph'
  | 'p3-paragraph'
  | 'sub-heading';

interface ParagraphProps {
  type?: ParagraphType;
  styleType?: ParagraphStyleType;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  adjustStyles?: AdjustStyles;
}

export interface AdjustStyles {
  textAlign?: React.CSSProperties['textAlign'];
  color?: React.CSSProperties['color'];
  fontWeight?: React.CSSProperties['fontWeight'];
  fontFamily?: React.CSSProperties['fontFamily'];
  lineHeight?: React.CSSProperties['lineHeight'];
  letterSpacing?: React.CSSProperties['letterSpacing'];
  fontSize?: React.CSSProperties['fontSize'];
  marginBlock?: React.CSSProperties['marginBlock'];
}

export const Paragraph: React.FC<ParagraphProps> = ({
  type = 'p',
  className,
  styleType = 'p3-paragraph',
  children,
  adjustStyles,
}) => {
  const adjustedStyles = {
    ['--font-family' as string]: adjustStyles?.fontFamily,
    ['--font-size' as string]: adjustStyles?.fontSize,
    ['--font-weight' as string]: adjustStyles?.fontWeight,
    ['--letter-spacing' as string]: adjustStyles?.letterSpacing,
    ['--line-height' as string]: adjustStyles?.lineHeight,
    ['--text-align' as string]: adjustStyles?.textAlign,
    ['--text-color' as string]: adjustStyles?.color,
    ['--margin-block' as string]: adjustStyles?.marginBlock,
  };

  const ParagraphTag = `${type}` as 'p' | 'span';

  return (
    <ParagraphTag
      className={clsx(
        styles['paragraph'],
        styles[`paragraph__${styleType}`],
        className,
      )}
      style={adjustedStyles}
    >
      {children}
    </ParagraphTag>
  );
};
